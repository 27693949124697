import Vuex from "vuex";
import Vue from "vue";
import store from "../..";

Vue.use(Vuex);

export const features = {
  namespaced: true,
  state: () => ({
    features: null,
  }),
  getters: {},
  mutations: {
    SET_FEATURES: (state, data) => {
      state.features = data;
    },
  },
  actions: {
    fetchFeatures({ commit }) {
      const url = `features`;
      return this.$axios
        .get(url)
        .then((response) => {
          commit("SET_FEATURES", response.data.hospital_services);
        })
        .catch((error) => {
          console.log("Error fetching features: " + error);
        });
    },
    toggleFeature({ dispatch }, data) {
      const url = `features`;
      return this.$axios
        .patch(url, data)
        .then((response) => {
          store.commit("alerts/SET_SHOW_ALERT", response.data.message);
          dispatch("fetchFeatures");
        })
        .catch((error) => {
          store.commit("alerts/SET_SHOW_ALERT", error.response.data.message);
          console.error("Error updating features: " + error);
        });
    },
  },
};
