<template>
  <v-app
    :class="{
      'bg-image': $route.name === 'login' && !$vuetify.breakpoint.xs,
    }"
  >
    <NavComponents v-if="!noNavBarRoutes.includes($route.name)" />
    <!-- Container -->
    <v-container :style="containerWidth" class="container-height">
      <v-main class="flex-1">
        <AlertComponent v-if="!noAlertRoutes.includes($route.name)" />
        <router-view />
      </v-main>
    </v-container>

    <FooterComponent />
  </v-app>
</template>

<script>
import NavComponents from "./layouts/NavComponents.vue";
import FooterComponent from "./layouts/Footer/FooterComponent.vue";
import ContainerBreakpoint from "./mixins/ContainerBreakpoint";
import AlertComponent from "./components/AlertComponent.vue";
export default {
  name: "App",
  mixins: [ContainerBreakpoint],
  data: () => ({
    noNavBarRoutes: [
      "login",
      "reroute",
      "public-file-view",
      "Minalin Dialysis Center",
    ],
    noContainerRoutes: [
      "login",
      "dialysis-files-view",
      "consultation-files-view",
      "public-file-view",
      "reroute",
    ],
    noAlertRoutes: ["dashboard"],
  }),
  components: {
    NavComponents,
    FooterComponent,
    AlertComponent,
  },
  computed: {
    containerWidth() {
      if (!this.noContainerRoutes.includes(this.$route.name)) {
        return `max-width: ${this.breakpointVal};`;
      } else if (this.$route.name === "reroute") {
        return `max-width: 1280px`;
      } else {
        return `max-width: 100%; padding: 0;`;
      }
    },
  },
};
</script>

<style scoped>
.bg-image {
  background: url(./assets/login/loginBg.svg) no-repeat center center/cover;
}

.container-height {
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
}
</style>
